import './App.scss';
import ImageTR from './assets/ink-tr.png'
import ImageBL from './assets/ink-bl.png'
import Texture from './assets/texture.jpg'

function App() {
  return (
    <div className="App" >
      <header className="App-header" style={{backgroundImage:`url(${Texture})`}}>
        <div className='content'>
        <h1>B<span>.</span>D<span>.</span>E<span>.</span>S<span>.</span></h1>
        <h3>Bishop Document Examination Services</h3>
        <p>Contact: <a href="tel:206.651.5077">206.651.5077</a> | <a href="mailto:info@bdes.us">info@bdes.us</a></p>
        <p>(Site Currently Under Construction)</p>
        </div>
      </header>
      <img className='ink-tr' src={ImageTR} alt='ink1' />
      <img className='ink-bl' src={ImageBL} alt='ink2' />
    </div>
  );
}

export default App;
